import React from 'react';

interface BarChartProps {
  cumulative_average_score: number;
  max_cumulative_score: number;
  bar_height?: string;
  bar_width?: string;
  bar_color?: string;
  min_width?: string;
  font_size?: string;
  line_height?: string;
  letter_spacing?: string;
  margin_left?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  cumulative_average_score,
  max_cumulative_score,
  bar_height = '30px',   // Default height
  bar_width = '300px',   // Default width
  bar_color = '#2FA9E3',  // Default bar color
  min_width = '375px', // Default min width
  font_size = '20px', // Default font_size
  line_height = '20px', // Default line_height
  letter_spacing = '-0.4px', // Default letter_spacing
  margin_left = '5px', // Default margin_left
}) => {
  // Calculate percentage values for the bar chart
  const progressPercentage = (cumulative_average_score / max_cumulative_score) * 100;

  return (
    <div style={{ ...containerStyle, minWidth: min_width }}>
      {/* The bar container */}
      <div style={{ ...wholeBarStyle, width: bar_width, height: bar_height }}>
        {/* The blue progress bar */}
        <div style={{ ...progressBarStyle, width: `${progressPercentage}%`, backgroundColor: bar_color }}>
          <span style={{
            ...labelStyle,
            fontSize: font_size,
            lineHeight: line_height,
            letterSpacing: letter_spacing
          }}>
            {`${cumulative_average_score.toFixed(2)}`}
          </span>
        </div>
      </div>
      {/* The max value label outside the bar */}
      <span style={{
        ...maxLabelStyle,
        marginLeft: margin_left,
        fontSize: font_size,
        lineHeight: line_height,
        letterSpacing: letter_spacing
      }}>
        {`${max_cumulative_score}`}
      </span>
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  display: 'flex',        // Flexbox layout for side-by-side bar and max label
  alignItems: 'center',   // Align vertically centered
  // justifyContent: 'start',
  position: 'relative',
  // marginRight: '5em'   // Relative positioning for the container
};

// Correctly typed styles
const wholeBarStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#B3B3B3', // Light gray background for the bar
  overflow: 'hidden',
  position: 'relative',   // Relative positioning for the container
};

const progressBarStyle: React.CSSProperties = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',               // Text color inside the bar
  fontWeight: 'bold',
};

const labelStyle: React.CSSProperties = {
  padding: '0 10%',
  fontWeight: '900',
  color: '#FFF',
  fontFamily: "Inter",
  fontStyle: "normal",
};

const maxLabelStyle: React.CSSProperties = {
  color: '#053D58',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
};

export default BarChart;

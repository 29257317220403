// import React, { useCallback } from 'react';
// import { Grid } from '@mui/material';
// import { CustomAccountBranding } from '../../modules/Branding/type';

// interface AssessmentNavBarProps {
//   branding: CustomAccountBranding;
// }

// const AssessmentNavBar: React.FC<AssessmentNavBarProps> = ({ branding }) => {

//   return (
//     <Grid
//       container
//       sx={{ height: '5vh', marginTop: '20px', alignItems: 'center' }}
//     >
//       {/* Logo Section */}
//       <Grid
//         item
//         xs={2}
//         md={1}
//         container
//         alignItems="center"
//         justifyContent="center"
//       >
//         <img
//           style={{
//             width: '100%',
//             height: '5vh',
//             objectFit: 'contain',
//             borderBottom: `3px solid ${branding?.primary_color || 'gray'}`,
//             paddingBottom: '5px',
//           }}
//           src={branding?.company_logo_primary}
//           alt="Company Logo"
//         />
//       </Grid>

//       {/* Navigation Section */}
//       <Grid
//         item
//         xs={10}
//         md={11}
//         container
//         sx={{ flexFlow: "row" }}
//       >
//         <Grid
//           item
//           sx={{
//             borderBottom: `3px solid ${branding?.primary_color || 'gray'}`,
//             width: '100%',
//             height: "5vh",
//             marginTop: "auto",
//             paddingLeft: "2%",
//             alignContent: "space-evenly",
//           }}
//         >
//           Introduction/Overview
//         </Grid>

//         <Grid
//           item
//           sx={{
//             borderBottom: `3px solid ${branding?.primary_color || 'gray'}`,
//             width: '100%',
//             height: "5vh",
//             paddingLeft: "2%",
//             marginTop: "auto",
//             alignContent: "space-evenly",
//             // borderLeft: `3px solid ${branding?.primary_color || 'gray'} 50%`
//           }}
//         >
//           Supply Chain Assessment
//         </Grid>
//         <Grid
//           item
//           sx={{
//             // borderBottom: `3px solid ${branding?.primary_color || 'gray'}`,
//             paddingBottom: '5px',
//             width: '100%',
//             textAlign: 'center',
//             paddingLeft: "2%",
//             height: "4vh",
//             marginTop: "auto",
//             alignContent: "space-evenly"
//           }}
//         >
//           {/* Completion Confirmation */}
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// }

// export default AssessmentNavBar;







import React from 'react';
import { Grid, Box, Button } from '@mui/material';
import { CustomAccountBranding } from '../../modules/Branding/type';

interface AssessmentNavBarProps {
  branding: CustomAccountBranding;
  surveyName: string;
  currentTab: number;
  setCurrentTab: (tabIndex: number) => void;
}

const AssessmentNavBar: React.FC<AssessmentNavBarProps> = ({ branding, surveyName, currentTab, setCurrentTab }) => {
  const tabs = [
    "Introduction/Overview",
    surveyName,
    "Completion Confirmation"
  ];

  return (
    <Grid container sx={{ height: '5vh', marginTop: '20px', alignItems: 'center', width: '100%' }}>
      {/* Logo Section */}
      <Grid item xs={2} md={1} display='flex' alignItems="center" justifyContent="center">
        <img
          style={{
            width: '100%',
            height: '5vh',
            objectFit: 'contain',
            borderBottom: `3px solid ${branding?.primary_color || 'gray'}`,
            paddingBottom: '5px',
          }}
          src={branding?.company_logo_primary}
          alt="Company Logo"
        />
      </Grid>

      {/* Navigation Tabs */}
      <Grid item xs={12} md={11} sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center',
        borderBottom: `3px solid ${branding?.primary_color || 'gray'}`,
      }}>
        {tabs.map((tabName, index) => (
          <Grid
            key={index}
            item
            // onClick={() => setCurrentTab(index)}  // Handle tab change on click
            sx={{
              // width: '100%',
              height: '5vh',
              textAlign: 'center',
              // cursor: 'pointer',
              fontSize: '20px',
              fontFamily: 'Inter',
              fontWeight: currentTab === index ? 'bold' : 'normal',  // Highlight active tab
              color: currentTab === index ? branding?.primary_color : '#626262',
              // '&:hover': { color: branding?.primary_color || '#626262' }
              cursor: 'default',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {tabName}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default AssessmentNavBar;

import React, { useEffect } from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../../redux/store.type';
import useUserSummary from '../hooks/useUserSummary';
import Typography from '@mui/material/Typography';
import { useBranding } from '../../Branding/reducers/getBranding';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';
import usePostEvaluationList from '../../Evaluations/hooks/usePostEvaluationList';
import useGetAccount from '../../Assessments/hooks/useGetAccount';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import ClientMetricBox from '../../../components/DataChart/ClientMetricBox';
import usePostEvaluationScoreHistory from '../../Evaluations/hooks/usePostEvaluationScoreHistory';
import { EvaluationScoreHistoryResponse as EvaluationScoreHistoryResponse } from '../../Evaluations/type';
import EvaluationScoreHistoryLineChart from './EvaluationScoreHistoryLineChart';

const ClientDashboard = () => {
  const { getAllEvaluations } = usePostEvaluationList();
  const { postEvaluationScoreHistory: postEvaluationScoreHistory } = usePostEvaluationScoreHistory();
  const getAccountResponse = useGetAccount();
  const navigate = useNavigate();
  const branding = useBranding();

  const dashboardAccess = branding?.active_feature_flags?.is_dashboard_customer_flag === true;

  const loggedInUserAccountId = useSelector((state: RootStoreType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.authReducers.user.currentUser?.account_id;
  });

  useEffect(() => {
    if (!dashboardAccess) {
      navigate('/account-list');
    }
  }, []);

  useEffect(() => {
    getAllEvaluations({
      // id: '',
      account_id: loggedInUserAccountId,
      // page: 1,
      // search: '',
      evaluation_status: ''
    });

    postEvaluationScoreHistory({
      account_id: loggedInUserAccountId
    });
  }, []);

  useEffect(() => {
    if (loggedInUserAccountId) {
      getAccountResponse.getAccount(`${loggedInUserAccountId}`);
    }
  }, [loggedInUserAccountId]);

  const { getSummary } = useUserSummary();

  useEffect(() => {
    getSummary();
  }, []);

  const accountDetails = useAppSelector((state: RootStoreType) => {
    return state.assessmentReducers.accountInfoReducer.accountDetail;
  });

  const primaryContact = accountDetails?.account_contacts.find(contact => contact.is_primary_contact === true);

  const evaluationDetails = useSelector((state: RootStoreType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.allEvaluationReducers.postEvaluationListReducer
      .evaluationResponse?.results;
  });

  const userSummary = useSelector((state: RootStoreType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.authReducers.userSummary.userSummary;
  });

  const evaluationScoreHistory: EvaluationScoreHistoryResponse | null =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationScoreHistoryReducer
        .evaluationScoreHistoryResponse;
    });

  const serviceCards = [
    {
      category: 'General Business',
      subheading: 'Let your MEP know you are interested in this assessment.',
      description: 'The general business assessment is a holistic evaluation that identifies improvement opportunities for a manufacturer to boost efficiency, productivity, and market competitiveness.',
      // features: [
      //   'Access to all basic features',
      //   'Basic reporting and analytics',
      //   'Up to 10 individual users',
      //   '20GB individual data each user',
      //   'Basic chat and email support',
      // ],
    },
    {
      category: 'Food & Beverage',
      subheading: 'Let your MEP know you are interested in this assessment.',
      description: 'The food and beverage assessment is a comprehensive evaluation focusing on improving production processes, quality control, and market positioning to enhance the competitiveness and profitability of food and beverage manufacturers.',
      // features: [
      //   '200+ integrations',
      //   'Advanced reporting and analytics',
      //   'Up to 20 individual users',
      //   '40GB individual data each user',
      //   'Priority chat and email support',
      // ],
    }
  ];

  const mockObject = [
    { name: '1-20-24', Pillar1: 4, Pillar2: 11, Pillar3: 6 },
    { name: '2-18-24', Pillar1: 14, Pillar2: 23, Pillar3: 31 },
    { name: '3-3-24', Pillar1: 23, Pillar2: 23, Pillar3: 33 },
    { name: '3-23-24', Pillar1: 40, Pillar2: 31, Pillar3: 44 },
    { name: '4-12-24', Pillar1: 38, Pillar2: 25, Pillar3: 36 },
    { name: '5-11-24', Pillar1: 45, Pillar2: 37, Pillar3: 40 }
  ];

  return (
    <Box sx={{ maxWidth: '1540px' }}>
      {userSummary ? (
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} md={9}>
            <Typography
              variant="h5"
              component="div"
              marginTop={-2}
              sx={{
                fontSize: 18,
                color: 'rgba(28,28,28, 0.40)',
                margin: '0 0 24px 0'
              }}
            >
              My Company Profile
            </Typography>
            <div style={{ margin: '10' }}>
              <Grid container sx={{ mb: 2 }}>
                {accountDetails?.company_logo && (
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'left' }}>
                    <div>
                      <img
                        style={{
                          width: '80%',
                          height: '90px',
                          maxWidth: '100%',
                          maxHeight: '100%',
                          margin: '0 25px'
                        }}
                        src={require('../../../assets/Resilience_Logo.png')}
                        alt="Company Profile Logo"
                      />
                    </div>
                  </Grid>
                )}
                {primaryContact?.contact_photo && (
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'right', padding: '0 25px' }}>
                    <div style={{ display: 'inline-flex' }}>
                      <img style={{
                        width: '110px',
                        height: '110px',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'cover',
                        borderRadius: '50%',
                        margin: '0 15px'
                      }}
                      src={require('../../../assets/users/user1.png')}
                      alt="Primary Contact" />
                      <div style={{ alignItems: 'center' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Hello Jane</Typography>

                        {(primaryContact?.first_name || primaryContact?.last_name) && (
                          <Typography variant="body2">
                            {primaryContact.first_name} {primaryContact?.last_name}, Account Manager
                          </Typography>
                        )}

                        {primaryContact?.phone && (
                          <Typography variant="body2">
                            {primaryContact.phone}
                          </Typography>
                        )}

                        {primaryContact?.email && (
                          <Typography variant="body2">
                            {primaryContact.email}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>

            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography component="div" sx={{ fontSize: 18, fontWeight: 'bold', mb: 1, color: '#0e274c' }}>
                  Performance Summary
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ mb: 3 }}>
              <Grid item xs={4} sx={{
                fontWeight: 'bold',
              }}>
                <ClientMetricBox metricTitle="Total Assessments" metricValue={7} metricIcon="Assess" />
              </Grid>
              <Grid item xs={4}>
                <ClientMetricBox metricTitle="Total Recommendations" metricValue={29} metricIcon="Recommend" />
              </Grid>
              <Grid item xs={4}>
                <ClientMetricBox metricTitle="Completed Projects" metricValue={5} metricIcon="Complete" />
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                border: '1px solid #CDCDCD',
                borderRadius: '16px',
                padding: '20px 8px 10px 8px',
                marginBottom: '24px',
              }}
            >
              <Typography
                component="div"
                sx={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  margin: '0 0 15px 0',
                  color: '#0e274c'
                }}
              >
                Performance Over Time: Business Assessment (V1.1)
              </Typography>

              <Grid item xs={8}>
                <Typography
                  component="div"
                  sx={{ fontSize: 12 }}
                  style={{
                    color: '#B4B4B4',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  0 - 20 Deeper Understanding &nbsp; &nbsp; 21 - 40 Needs
                  Improvement &nbsp; &nbsp; 41 - 50 Meets Expectations
                </Typography>
                <LineChart
                  width={580}
                  height={250}
                  data={mockObject}
                  margin={{
                    top: 10,
                    right: 10,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    padding={{ left: 30, right: 30 }}
                    style={{ fontFamily: 'Roboto', fontSize: 14 }}
                  />
                  <YAxis style={{ fontFamily: 'Roboto', fontSize: 14 }} />
                  <Tooltip />

                  <Line
                    type="monotone"
                    dataKey="Pillar1"
                    stroke="#3787bc"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="Pillar2" stroke="#93241b" />
                  <Line type="monotone" dataKey="Pillar3" stroke="#0e274c" />
                </LineChart>
              </Grid>
              <Grid item xs={4}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ color: '#3787bc', fontSize: '24px' }}>
                        {'\u25A0'}
                      </TableCell>
                      <TableCell sx={{ padding: '5px', color: '#0e274c' }}>
                        Business Development & Strategy
                      </TableCell>
                      <TableCell sx={{ color: '#0e274c' }}>36%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: '#93241b', fontSize: '24px' }}>
                        {'\u25A0'}
                      </TableCell>
                      <TableCell sx={{ padding: '5px', color: '#0e274c' }}>
                        Operational Excellence & Efficiency
                      </TableCell>
                      <TableCell sx={{ color: '#0e274c' }}>26%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: '#0e274c', fontSize: '24px' }}>
                        {'\u25A0'}
                      </TableCell>
                      <TableCell sx={{ padding: '5px', color: '#0e274c' }}>
                        Technology Integration & Workforce Development
                      </TableCell>
                      <TableCell sx={{ color: '#0e274c' }}>46%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: 'green',
                          fontSize: '30px',
                          fontWeight: 'bold',
                        }}
                      >
                        {'\u21EA'}
                      </TableCell>
                      <TableCell sx={{ padding: '5px' }}>
                        Overall Improvement
                      </TableCell>
                      <TableCell>26%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <Grid container columnGap={1.5} mb={3}>
              <Grid
                container
                xs={5.9}
                sx={{
                  background: '#E3e4e4',
                  borderRadius: '5px',
                  padding: '10px',
                }}
              >
                <Grid item sx={{ mb: 2 }}>
                  <Typography variant="subtitle1"
                    style={{
                      fontWeight: 'bold',
                      color: '#0e274c',
                      fontSize: 18
                    }}>
                    All Assessments
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontSize: '12px', color: '#0e274c' }}
                  >
                    Comprehensive view of individual assessments, categorized by
                    type. Each assessment is clearly marked to reflect its
                    current status, ensuring you can easily track progress and
                    completion. Dive into specific details by selecting an
                    assessment type.
                  </Typography>
                </Grid>

                <Grid
                  container
                  columnGap={1.5}
                  justifyContent={'center'}
                  xs={12}
                  sx={{
                    background: '#fff',
                    border: '1px solid #959696',
                    borderRadius: '5px',
                    padding: '10px',
                    marginBottom: '5px',
                  }}
                >
                  <Grid item xs={3} alignContent={'center'}>
                    <Typography
                      variant="body2"
                      sx={{
                        background: '#D6D7D7',
                        borderRadius: '5px',
                        padding: '5px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#0e274c',
                      }}
                    >
                      In Progress
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '14px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      Business Assessment (V 1.1) Third Taken
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '12px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      Start to Close: Dec 15, 2024 - Jan 01, 2025 (Duration 17
                      Days)
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnGap={1.5}
                  justifyContent={'center'}
                  xs={12}
                  sx={{
                    marginBottom: '5px',
                    background: '#fff',
                    border: '1px solid #959696',
                    borderRadius: '5px',
                    padding: '10px',
                  }}
                >
                  <Grid item xs={3} alignContent={'center'}>
                    <Typography
                      variant="body2"
                      sx={{
                        background: '#93c47d',
                        borderRadius: '5px',
                        padding: '5px',
                        textAlign: 'center',
                        fontSize: '12px',
                        marginBottom: '10px',
                        color: '#0e274c',
                      }}
                    >
                      Complete
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ fontSize: '11px', color: '#0e274c' }}
                    >
                      Download Report
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '14px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      Business Assessment (V 1.1) Third Taken (Duration 146
                      Days)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '12px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      Start to Close: Dec 15, 2024 - Jan 01, 2025 (Duration 17
                      Days)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '12px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      13 Responses
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                xs={5.9}
                sx={{
                  background: '#E3e4e4',
                  borderRadius: '5px',
                  padding: '10px',
                }}
              >
                <Grid item sx={{ mb: 2 }}>
                  <Typography variant="subtitle1"
                    sx={{
                      fontWeight: 'bold',
                      color: '#0e274c',
                      fontSize: 18
                    }}>
                    Projects
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: '12px', color: '#0e274c' }}
                  >
                    High level view of current project status and stages.
                  </Typography>
                </Grid>

                <Grid
                  container
                  columnGap={1.5}
                  justifyContent={'center'}
                  xs={12}
                  sx={{
                    background: '#fff',
                    border: '1px solid #959696',
                    borderRadius: '5px',
                    padding: '10px',
                    marginBottom: '5px',
                  }}
                >
                  <Grid item xs={3} alignContent={'center'}>
                    <Typography
                      variant="body2"
                      sx={{
                        background: '#D6D7D7',
                        borderRadius: '5px',
                        padding: '5px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#0e274c',
                      }}
                    >
                      In Progress
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '14px', color: '#0e274c' }}
                    >
                      Learn Six Sigma Course
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '12px', color: '#0e274c' }}
                    >
                      Start: May 25, 2025 Est Close: June 01, 2025
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnGap={1.5}
                  justifyContent={'center'}
                  xs={12}
                  sx={{
                    marginBottom: '5px',
                    background: '#fff',
                    border: '1px solid #959696',
                    borderRadius: '5px',
                    padding: '10px',
                  }}
                >
                  <Grid item xs={3} alignContent={'center'}>
                    <Typography
                      variant="body2"
                      sx={{
                        background: '#93c47d',
                        borderRadius: '5px',
                        padding: '5px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#0e274c',
                        marginBottom: '10px',
                      }}
                    >
                      Complete
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '11px', color: '#0e274c' }}
                    >
                      Download Report
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '14px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      Workforce Funding
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '12px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      Start: May 25, 2025 Est Close: June 01, 2025
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnGap={1.5}
                  justifyContent={'center'}
                  xs={12}
                  sx={{
                    marginBottom: '5px',
                    background: '#fff',
                    border: '1px solid #959696',
                    borderRadius: '5px',
                    padding: '10px',
                  }}
                >
                  <Grid item xs={3} alignContent={'center'}>
                    <Typography
                      variant="body2"
                      sx={{
                        background: '#93c47d',
                        borderRadius: '5px',
                        padding: '5px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#0e274c',
                        marginBottom: '10px',
                      }}
                    >
                      Complete
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '11px', color: '#0e274c' }}
                    >
                      Download Report
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '14px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      Cybersecurity Project
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '12px',
                        marginBottom: '3px',
                        color: '#0e274c',
                      }}
                    >
                      Start: May 25, 2025 Close: June 01, 2025
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*end main content*/}
          {/* Start sidebar content */}

          <Grid item xs={12} md={3}>
            <Box
              sx={{
                backgroundColor: '#e5ecf6',
                padding: '30px 20px',
                borderRadius: '16px',
                color: '#0e274c'
              }}
            >
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontWeight: 'bold',
                  color: '#0e274c',
                  fontSize: 16,
                }}
              >
                My MEP Center
              </Typography>
              <Grid container spacing={2} sx={{ mb: 0 }} justifyContent="center">
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <img
                    style={{
                      width: '80%',
                      height: 'auto',
                      marginTop: '10px',
                      marginBottom: '10px',
                      objectFit: 'contain',
                      display: 'inline-block',
                    }}
                    src={require('../../../assets/Impact_MEP_Logo.png')}
                    alt=""
                  />
                </Grid>
              </Grid>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 14,
                  mb: '25px'
                }}
              // dangerouslySetInnerHTML={{ __html: branding?.dashboard_mep_description || '' }}
              >
                We are here to support small and medium-sized companies across all industries who are committed to investing time, money, and resources to develop and enhance their business operations. Our goal is to help you discover and maximize financial opportunities. Acting as a general consultant, we offer a wealth of knowledge and cater to various industry-specific needs.
              </Typography>
              <Grid container sx={{ mb: '25px' }}>
                <Grid item xs={6} style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '11px'
                }}>
                  AE Expert (Primary)
                </Grid>
                <Grid item xs={6} style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '11px'
                }}>
                  AE Expert
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <img style={{ width: '65px', height: '65px', borderRadius: '50%' }}
                    src={require('../../../assets/users/user5.png')}
                    alt="AE Expert (Primary)"
                  />
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <img style={{ width: '65px', height: '65px', borderRadius: '50%' }}
                    src={require('../../../assets/users/user2.png')}
                    alt="AE Expert"
                  />
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 0, fontFamily: 'helvetica' }}>
                <Grid item xs={12}>
                  <h5>Other MEP Assessment Options:</h5>
                  <ul style={{ fontSize: '14px', marginBottom: '15px' }}>
                    <li>General</li>
                    <li>Workforce</li>
                    <li>Cyber-Security</li>
                    <li>Industry 4.0</li>
                  </ul>
                  <h5>MEP Services</h5>
                  <ul style={{ fontSize: '14px', marginBottom: '15px' }}>
                    <li>Partner & Funding Opportunities</li>
                    <li>Education Opportunities</li>
                    <li>Workshops</li>
                    <li>Process Improvement Training</li>
                  </ul>
                  <h5>Upcoming Events</h5>
                  <ul style={{ fontSize: '14px', marginBottom: '15px' }}>
                    <li>Manufacturing B2B Tradeshow</li>
                    <li>Innovation & Technology Summit</li>
                    <li>American Food Manufacturing Summit</li>
                    <li>Advanced Technology Workshop</li>
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ margin: '25px 35%', fontWeight: '600', color: '#A3A3A3' }}>
          {'No Evaluations available'}
        </Box>
      )}
    </Box>
  );
};

export default ClientDashboard;

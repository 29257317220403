import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';

import { useBranding } from '../../Branding/reducers/getBranding';
import LegacyEditEvaluation from '../../Evaluations/EditEvaluation/components/LegacyEditEvaluation';
import EditAssessment from './components/EditAssessment';

const EditAssessmentContainer = () => {
  const branding = useBranding();
  const [loading, setLoading] = useState(true);
  const [useLegacyEndpoints, setUseLegacyEndpoints] = useState<boolean | null>(null);

  useEffect(() => {
    if (branding !== null) {
      // When branding data is fetched, update the loading state and the useLegacyEndpoints flag
      setUseLegacyEndpoints(branding?.active_feature_flags?.use_legacy_v1_endpoints_flag === true);
      setLoading(false);
    }
  }, [branding]);

  if (loading || !branding) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return useLegacyEndpoints ? (
    <LegacyEditEvaluation />
  ) : (
    <EditAssessment
      branding={branding}
    />
  );
};

export default EditAssessmentContainer;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Button,
  useTheme,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
  FieldName,
  FieldValue,
  GridItemFieldName,
  GridItemFieldValue,
} from '../../../Assessments/AssessmentDetails/components/EvaluationDetails.style';
import { PieChart, Pie, Cell } from 'recharts';
import {
  EvaluationTextAnswersResponse,
  EvaluationMCAnswersResponse,
  ResultEvaluationResponse,
  EvaluationResultAnalysisResponse,
  EvaluationAssessorScoreData
} from '../../type';
import {
  CustomButton,
  Typography10px400,
  Typography12px400,
  Typography14px400,
  Typography14px600,
} from '../../../../styles/Global.styles';
import StaticLikertScale from './StaticLikertScale';
import { useForm, FieldValues, Controller } from 'react-hook-form';
import { EvaluationResultPayload } from '../../reducers/putEvaluationResultAnalysis';
import translation from '../../../../locales/en/translation.json';
import translate from '../../../../locales/translate';
import { useAppSelector } from '../../../../redux/store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import usePutEvaluationResultAnalysis from '../../hooks/usePutEvaluationResultAnalysis';
import useGetEvaluationAnalysisResults from '../../hooks/useGetEvaluationAnalysisResult';
import usePostEvaluationResultAnalysis from '../../hooks/usePostEvaluationResultAnalysis';
import useGetEvaluationQuestionCategories from '../../hooks/useGetEvaluationQuestionCategory';
import usePostEvaluationMCAnswers from '../../hooks/usePostEvaluationMCAnswers';
import usePostEvaluationTextAnswers from '../../hooks/usePostEvaluationTextAnswers';
import usePostEvaluationAssessorScore from '../../hooks/usePostEvaluationAssessorScore';
import { useBranding } from '../../../Branding/reducers/getBranding';
import {
  FormFieldContainer,
  SaveButton
} from '../../../../modules/Accounts/CreateClient/CreateClient.styles';
import EvaluationAnalysisNotesField from '../../Components/EvaluationAnalysisNotesField';
import EvaluationAnalysisNotes from './EvaluationAnalysisScoreNotes';
import { evaluationFields } from '../../Constants/EvaluationAnalysisNotes';

// V2 HERE
// -setLocalScores
// -postEvaluationResponseTextAnswers
// -postEvaluationResponseMCAnswers
// -postResultAnalysisSurvey
// -evaluationAnalysisResult
// -updateEvaluationResultAnalysis
// -setRowOfData

const placeholderText = {
  color: '#B5B5C3',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '5px',
};

const innerText = {
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '5px',
};

const EvaluationAnalysis = (prop: {
  id: string;
  evaluationQuestionCategoryId: string;
  openModal;
}) => {
  const usePutEvaluationResultAnalysisReducer =
    usePutEvaluationResultAnalysis();
  const { postResultAnalysisSurvey } = usePostEvaluationResultAnalysis();
  const evaluationQuestionCategoryReducer =
    useGetEvaluationQuestionCategories();
  const useGetEvaluationAnalysisResultsReducer =
    useGetEvaluationAnalysisResults();
  const { postEvaluationResponseMCAnswers } = usePostEvaluationMCAnswers();
  const { postEvaluationResponseTextAnswers } = usePostEvaluationTextAnswers();
  const { postEvaluationAssessorScores } = usePostEvaluationAssessorScore();
  const branding = useBranding();

  const theme = useTheme();

  const evaluationAnalysisResponse: ResultEvaluationResponse | null =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationResultAnalysisReducer
        .resultEvaluationResponse;
    });

  const evaluationMCAnswers: EvaluationMCAnswersResponse | null =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationMCAnswersReducer
        .evaluationMCAnswersResponse;
    });

  const evaluationTextAnswers: EvaluationTextAnswersResponse | null =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationTextAnswersReducer
        .evaluationTextAnswersResponse;
    });

  const [rowsOfData, setRowOfData] = React.useState<
    { key: string; value: number }[]
  >([]);

  const COLORS = ['#BAEDBD', '#C6C7F8', '#000', '#F0BB00', '#FF285C'];

  const evaluationQuestionCategories = useAppSelector((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.allEvaluationReducers.getEvaluationQuestionCategoryReducer
      .evaluationQuestionCategories?.results;
  });

  React.useEffect(() => {
    evaluationQuestionCategoryReducer.getQuestionCategories();
  }, []);

  const [changedScores, setChangedScores] = React.useState<Map<number, string>>(new Map());

  const [localScores, setLocalScores] = React.useState<EvaluationAssessorScoreData[]>([]);

  const handleInputChange = (
    i: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let inputValue = event.target.value;
    let assessorScore: number | string = "";
  
    // Extract and parse min and max, with NaN handling
    let min = parseFloat(event.target.min) || 0;
    let max = parseFloat(event.target.max) || 5;
  
    // Set default values if min or max are NaN
    if (isNaN(min)) min = -Infinity;
    if (isNaN(max)) max = Infinity;
  
    if (inputValue !== "") {
      let numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue)) {
        numericValue = Math.min(Math.max(numericValue, min), max);
        assessorScore = numericValue;
        inputValue = numericValue.toString();
      } else {
        inputValue = "";
      }
    }
  
    setLocalScores((prevScores) =>
      prevScores.map((score, idx) =>
        idx === i ? { ...score, assessor_score: assessorScore } : score
      )
    );
  
    setChangedScores((prevChanges) => new Map(prevChanges).set(i, inputValue));
  };
  

  const handleAssessorScoreSubmit = (event) => {
    event.preventDefault();
    const updatedScores = Array.from(changedScores.entries()).map(([index, score]) => ({
      ...localScores?.at(index),
      assessor_score: score == "" ? undefined : score,
    }));
    postEvaluationAssessorScores(updatedScores as EvaluationAssessorScoreData[]);
  };

  React.useEffect(() => {
    if (evaluationAnalysisResponse?.individual_answer_score) {
      const question_score_data = evaluationAnalysisResponse?.individual_answer_score.map((eachQuestionObj) => ({
        question_id: eachQuestionObj.question_id,
        assessor_score: eachQuestionObj.assessor_score === 0 || eachQuestionObj.assessor_score === 0.0
          ? 0
          : eachQuestionObj.assessor_score ?? '',

        question_category_id: prop.evaluationQuestionCategoryId,
        evaluation_id: prop.id
      }))
      setLocalScores(question_score_data);
    }
  }, [evaluationAnalysisResponse?.individual_answer_score]);

  const shouldRenderAssessorScoreData = branding?.active_feature_flags?.is_assessor_score_customer_flag == true
  const resultAnalysisForm = useForm<FieldValues & EvaluationResultPayload>({
    defaultValues: {
      root_causes: '',
      strengths: '',
      possible_offerings: [],
      offerings: '',
      challenges_threats: [],
      go_forward_plan: '',
      key_priorities: '',
      development_opportunities: '',
      implementation_details_and_timing: '',
    },
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = resultAnalysisForm;

  const availableResultData: EvaluationResultAnalysisResponse | null =
    useAppSelector((state) => {
      return state.allEvaluationReducers.getEvaluationResultAnalysisReducer
        .evaluationResultResponse;
    });

  const question_category_string: string = availableResultData?.question_category_name || 'Default Category';

  const evaluation_name: string = availableResultData?.evaluation_name || 'Default Evaluation';

  const skipCategoriesForEvaluation = ['recruitment, hiring, onboarding, turnover, growth & promotion', 'upskilling and skill building', 'benefits', 'worker financial wellness', 'employee support and working environment', 'workforce engagement survey'];
  const skipEvaluationName = "employer of choice"

  React.useEffect(() => {
    useGetEvaluationAnalysisResultsReducer.evaluationAnalysisResult(
      prop.id,
      prop.evaluationQuestionCategoryId,
    );

    postResultAnalysisSurvey({
      evaluation_id: prop.id,
      question_category_id: prop.evaluationQuestionCategoryId,
    });

    postEvaluationResponseMCAnswers({
      evaluation_id: prop.id,
      question_category_id: prop.evaluationQuestionCategoryId,
    });

    postEvaluationResponseTextAnswers({
      evaluation_id: prop.id,
      question_category_id: prop.evaluationQuestionCategoryId,
    });
  }, []);

  React.useEffect(() => {
    if (evaluationAnalysisResponse) {
      useGetEvaluationAnalysisResultsReducer.evaluationAnalysisResult(
        prop.id,
        prop.evaluationQuestionCategoryId,
      );
    }
  }, [evaluationAnalysisResponse]);

  React.useEffect(() => {
    if (availableResultData) {
      const possibleOfferingValuesString =
        availableResultData.possible_offerings?.join('\n');
      const challengeThreatsValuesString =
        availableResultData.challenges_threats?.join('\n');
      setValue('strengths', availableResultData.strengths);
      setValue('root_causes', availableResultData.root_causes);
      setValue('challenges', challengeThreatsValuesString);
      setValue('go_forward_plan', availableResultData.go_forward_plan);
      setValue('key_priorities', availableResultData.key_priorities);
      setValue(
        'development_opportunities',
        availableResultData.development_opportunities,
      );
      setValue(
        'implementation_details_and_timing',
        availableResultData.implementation_details_and_timing,
      );
      setValue('offerings', possibleOfferingValuesString);
    }
    if (evaluationAnalysisResponse) {
      const rowArray: { key: string; value: number }[] = [];
      for (const key in evaluationAnalysisResponse.overall_scores) {
        const row = {
          key: key,
          value: evaluationAnalysisResponse.overall_scores[key] as number,
        };
        rowArray.push(row);
      }
      setRowOfData(rowArray);
    }
  }, [evaluationAnalysisResponse, availableResultData, setValue]);

  const onSubmit = (data: EvaluationResultPayload) => {
    data.possible_offerings =
      data.offerings && data.offerings.length > 0
        ? data.offerings?.split('\n').filter((i) => i)
        : undefined;
    data.offerings = undefined;

    data.challenges_threats =
      data.challenges && data.challenges.length > 0
        ? data.challenges?.split('\n').filter((i) => i)
        : undefined;
    data.challenges = undefined;
    usePutEvaluationResultAnalysisReducer.updateEvaluationResultAnalysis({
      params: {
        evaluation_id: prop.id,
        question_category_id: prop.evaluationQuestionCategoryId,
      },
      payload: data,
    });
  };
  const navigate = useNavigate();

  if (
    evaluationAnalysisResponse &&
    evaluationAnalysisResponse.responses_received === 0
  ) {
    toast.error('No response received till now.');
    navigate(`/evaluation-assessment-detail/${prop.id || ''}`);
  }

  return !evaluationAnalysisResponse ||
    !evaluationMCAnswers ||
    !evaluationTextAnswers ||
    !availableResultData ||
    useGetEvaluationAnalysisResultsReducer.loading ||
    useGetEvaluationAnalysisResultsReducer.error ? (
    <CircularProgress sx={{ margin: '20vh 48%' }} />
  ) : evaluationAnalysisResponse.average_evaluation_score === 0 ? (
    <Grid
      container
      sx={{
        backgroundColor: 'var(--primary-light, #F7F9FB)',
        padding: '20px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography14px600>
        {'No question is in this question category.'}
      </Typography14px600>
    </Grid>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        sx={{
          paddingTop: '30px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid item sm={4.7}>
          {!evaluation_name.toLowerCase().includes(skipEvaluationName) && !skipCategoriesForEvaluation.includes(question_category_string.toLowerCase()) && (
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'var(--primary-light, #F7F9FB)',
                padding: '30px',
                borderRadius: '12px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                }}
                variant="h5"
                component="h2"
                gutterBottom
              >
                {question_category_string}
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={12} sm={6} md={6} lg={6} position={'relative'}>
                  <Box
                    sx={{
                      height: '210px',
                    }}
                  >
                    <PieChart
                      width={400}
                      height={400}
                      style={{
                        position: 'absolute',
                        top: '-80px',
                        left: '-40px',
                      }}
                    >
                      <Pie
                        data={rowsOfData}
                        cx={120}
                        cy={200}
                        startAngle={0}
                        endAngle={360}
                        cornerRadius={50}
                        innerRadius={40}
                        outerRadius={70}
                        stroke="#fff"
                        strokeWidth={5}
                        fill="#8884d8"
                        paddingAngle={-8}
                        dataKey="value"
                      >
                        {rowsOfData.map((_, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'center',
                      position: 'absolute',
                      bottom: '-25px',
                      left: '30%',
                    }}
                  ></Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Grid container spacing={2}>
                    {rowsOfData.map((eachScore, i) => {
                      return (
                        <React.Fragment key={i}>
                          <GridItemFieldName item xs={9} sm={9}>
                            <Box
                              sx={{
                                width: '6px',
                                height: '6px',
                                borderRadius: '50%',
                                backgroundColor: COLORS[i],
                                float: 'left',
                                marginRight: '5px',
                                position: 'relative',
                              }}
                            ></Box>
                            <FieldName>{eachScore.key}</FieldName>
                          </GridItemFieldName>
                          <GridItemFieldValue item xs={3} sm={3}>
                            <FieldValue> {eachScore.value} </FieldValue>
                          </GridItemFieldValue>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box
            sx={{
              marginX: '10px',
              marginTop: '10px',
              marginBottom: '30px',
            }}
          >
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingBottom: '5px',
              }}
            >
              {translation.score}:
              {evaluationAnalysisResponse.average_evaluation_score}
            </Typography12px400>

            <Typography10px400
              sx={{
                color: '#B5B5C3',
              }}
            >
              {translate('evaluation_score_analysis_score_label_subtext', {
                responses_received:
                  evaluationAnalysisResponse.responses_received,
                target_responses: evaluationAnalysisResponse.target_responses,
              })}
            </Typography10px400>
          </Box>

          {evaluationFields.filter(
            (fieldConfig) =>
              !fieldConfig.displayFor || branding?.company_name.toLowerCase().includes(fieldConfig.displayFor)
          ).map((fieldConfig, index) => (
            <EvaluationAnalysisNotesField
              key={index}
              control={control}
              register={register}
              errors={errors}
              minRows={3}
              maxRows={8} // Add the maxRows prop here
              {...fieldConfig}
              minLength={fieldConfig.minLength ?? 0}
            />
          ))}

          {(
            <Grid
              item
              sm={12}
              sx={{
                margin: '30px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <CustomButton
                type="submit"
                sx={{
                  color: '#fff',
                  minWidth: '70px',
                  fontSize: '15px',
                }}
              //disabled={availableResultData.evaluation_status !== "Results Review"}
              >
                Save
              </CustomButton>
            </Grid>
          )}

        </Grid>

        <Grid item sm={7}>
          <Grid item
            sx={{
              display: 'flex',
              justifyContent: 'right', // Center text in the middle third
              paddingBottom: '12px',
            }}>
            {/* Wrap Typography and Button in a flex container */}
            <Typography12px400
              sx={{
                color: '#181C32',
                paddingTop: '6px',
                //paddingBottom: '5px',
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Question Answer Averages
            </Typography12px400>

            <Button
              endIcon={<BarChartIcon />}
              variant="contained"
              sx={{
                marginLeft: '11em',
                backgroundColor: theme.palette.primary.main,
                '&:hover': { backgroundColor: theme.palette.primary.main }
              }}
              onClick={prop.openModal}
            >
              Individual Responses
            </Button>
          </Grid>

          <Grid
            container
            sx={{
              backgroundColor: 'var(--primary-light, #F7F9FB)',
              padding: '20px',
              borderRadius: '12px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FormFieldContainer
              sx={{
                flexDirection: 'row'
              }}>
              {evaluationAnalysisResponse.individual_answer_score.map(
                (eachQuestionObj, i) => {
                  if (
                    shouldRenderAssessorScoreData
                  ) {
                    return (
                      <React.Fragment key={i}>
                        <Grid
                          item
                          sx={{
                            margin: '10px',
                          }}
                          sm={12}
                        >
                          <Typography14px400
                            sx={{
                              lineHeight: '20px',
                              marginBottom: '1.5%',
                              marginTop: '1%'
                            }}
                          >
                            {i + 1}. {eachQuestionObj.question}
                          </Typography14px400>
                        </Grid>
                        <Grid item key={i} sm={8} sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          <Grid item sm={4} sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                            <Typography12px400
                              sx={{
                                lineHeight: '19px',
                                marginLeft: '22px',
                              }}
                            >
                              Self-Score Average:
                            </Typography12px400>
                          </Grid>
                          <Grid item sm={8} sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <StaticLikertScale
                              value={Math.round(eachQuestionObj.score * 10) / 10}
                            />
                          </Grid>
                        </Grid>
                        {
                          localScores && localScores.length > 0 &&
                          <Grid item sm={4} sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <Grid item sm={8}>
                              <Typography12px400
                                sx={{
                                  lineHeight: '19px',
                                  paddingRight: '3%',
                                  textAlign: 'right'
                                }}
                              >
                                Assessor Score Input:
                              </Typography12px400>
                            </Grid>

                            <Grid item key={`${i}-input`} sm={3}>
                              <input
                                type="number"
                                value={
                                  localScores[i]?.assessor_score === 0 ?
                                    0 : localScores[i]?.assessor_score || ""
                                }
                                onChange={(event) => handleInputChange(i, event)}
                                style={{ width: '100%' }}
                                min={0}
                                max={5}
                                step={0.1}
                              />
                            </Grid>
                          </Grid>
                        }
                        <Grid item sm={12} sx={{
                          display: 'flow',
                          margin: 'auto',
                          marginTop: '3%'
                        }}>
                          <hr />
                        </Grid>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={i}>
                        <Grid
                          item
                          sx={{
                            margin: '10px',
                          }}
                          sm={7}
                        >
                          <Typography12px400
                            sx={{
                              lineHeight: '20px',
                            }}
                          >
                            {eachQuestionObj.question}
                          </Typography12px400>
                        </Grid>
                        <Grid item key={i} sm={4}>
                          <StaticLikertScale
                            value={Math.round(eachQuestionObj.score * 10) / 10}
                          />
                        </Grid>
                      </React.Fragment>
                    );
                  }
                },
              )}
              {shouldRenderAssessorScoreData &&
                <React.Fragment key="assessorScoreSubmitButton">
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      width: '99%',
                      marginTop: '0.5em'
                    }}
                  >
                    <SaveButton onClick={handleAssessorScoreSubmit} form="assessorScoreForm">Save Scores</SaveButton>
                  </Grid>
                </React.Fragment>
              }
            </FormFieldContainer>
          </Grid>

          {evaluationMCAnswers.questions_and_answers.length > 0 && (
            <>
              <Typography12px400
                sx={{
                  color: '#181C32',
                  paddingTop: '30px',
                  paddingBottom: '0px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Yes/No Question Answer Averages
              </Typography12px400>
              <Grid
                container
                sx={{
                  backgroundColor: 'var(--primary-light, #F7F9FB)',
                  padding: '20px',
                  borderRadius: '12px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                {evaluationMCAnswers.questions_and_answers.map(
                  (eachQuestionObj, i) => (
                    <React.Fragment key={i}>
                      <Grid item sx={{ margin: '10px' }} sm={7}>
                        <Typography12px400 sx={{ lineHeight: '20px' }}>
                          {eachQuestionObj.question_text}
                        </Typography12px400>
                      </Grid>
                      <Grid item sm={4}>
                        <StaticLikertScale
                          value={
                            Math.round(eachQuestionObj.average_score * 10) / 10
                          }
                        />
                      </Grid>
                    </React.Fragment>
                  ),
                )}
              </Grid>
            </>
          )}

          {evaluationTextAnswers.questions_and_answers.length > 0 && (
            <>
              <Typography12px400
                sx={{
                  color: '#181C32',
                  paddingTop: '30px',
                  paddingBottom: '0px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Text Entry Question Answers
              </Typography12px400>
              <Grid
                container
                sx={{
                  backgroundColor: 'var(--primary-light, #F7F9FB)',
                  padding: '20px',
                  borderRadius: '12px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                {evaluationTextAnswers.questions_and_answers.map(
                  (eachQuestionObj, i) => (
                    <React.Fragment key={i}>
                      <Grid item sx={{ margin: '10px' }} sm={7}>
                        <Typography12px400 sx={{ lineHeight: '20px' }}>
                          {eachQuestionObj.question_text}
                        </Typography12px400>
                      </Grid>
                      <Grid item key={i} sm={4}>
                        {/* Display each answer in a scrollable text box */}
                        {eachQuestionObj.answers.map((answer, index) => (
                          <Box
                            key={index}
                            sx={{
                              maxHeight: '150px',
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              padding: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <Typography12px400
                              sx={{
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                              }}
                            >
                              {answer.answer_text}
                            </Typography12px400>
                          </Box>
                        ))}
                      </Grid>
                    </React.Fragment>
                  ),
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default EvaluationAnalysis;

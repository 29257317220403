import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';

import { useBranding } from '../../Branding/reducers/getBranding';
import LegacyEvaluationDetailView from '../../Evaluations/EvaluationDetailView/components/v1/LegacyEvaluationDetailView';
import { AccountSettingsUser } from '../../Accounts/AccountSettings/AccountSettings';
import { RootStoreType } from '../../../redux/store.type';
import { useAppSelector } from '../../../redux/store';
import useGetAccount from '../hooks/useGetAccount';
import useAccountSettingsUsers from '../../Accounts/hooks/useGetAccountSettingsUsers';
import { useParams } from 'react-router-dom';
import AssessmentDetailsView from './components/AssessmentDetailsView';

const AssessmentDetailContianer = () => {
  const branding = useBranding();
  const [loading, setLoading] = useState(true);
  const [useLegacyEndpoints, setUseLegacyEndpoints] = useState<boolean | null>(null);
  const getAccountResponse = useGetAccount();
  const getAccountSettingsUsers = useAccountSettingsUsers();
  const { id } = useParams();

  useEffect(() => {
    if (branding !== null) {
      // When branding data is fetched, update the loading state and the useLegacyEndpoints flag
      setUseLegacyEndpoints(branding?.active_feature_flags?.use_legacy_v1_endpoints_flag === true);
      setLoading(false);
    }
  }, [branding]);

  if (loading || !branding || !id) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return useLegacyEndpoints ? (
    <LegacyEvaluationDetailView />
  ) : (
    <AssessmentDetailsView
      branding={branding}
      id={id}
    />
  );
};

export default AssessmentDetailContianer;

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CategoryScoreQuestionDetails } from '../../../type';
import LikertScaleResponseLineChart from '../QuestionResultDisplays/LikertScaleResponseLineChart';

export interface MultipleChoiceAnswerDistributionDetails {
    answer_id: number;
    answer_text: string;
    answer_response_count: number;
    answer_score: string;
    answer_display_order: number;
}

interface SurveySummaryProps {
    questionDetails: CategoryScoreQuestionDetails;
    displayLikertScale?: boolean;
}

// Helper function to find the answer closest to the average score
const getClosestAnswerText = (averageScore: number, distribution: MultipleChoiceAnswerDistributionDetails[]) => {
    let closestAnswer = distribution[0];
    let smallestDifference = Math.abs(averageScore - parseFloat(closestAnswer.answer_score));

    distribution.forEach((answer) => {
        const score = parseFloat(answer.answer_score);
        const difference = Math.abs(averageScore - score);

        if (difference < smallestDifference) {
            smallestDifference = difference;
            closestAnswer = answer;
        }
    });

    return closestAnswer.answer_text;
};

const MultipleChoiceResponseDisplay: React.FC<SurveySummaryProps> = ({
    questionDetails,
    displayLikertScale = false
}) => {
    const { average_score, multiple_choice_answer_distribution, total_response_count } = questionDetails;

    // Find the average answer text
    const averageAnswerText = getClosestAnswerText(average_score, multiple_choice_answer_distribution);

    return (
        <Box sx={{ margin: '20px 1.5em 0px 1.5em' }}>
            {/* Average Answer Section */}
            <Grid
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '8px',
                    marginBottom: '12px',
                }}>
                {/* <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                    }}>
                    <Typography
                        sx={{
                            color: '#053D58',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            lineHeight: '15px',
                            letterSpacing: '-0.36px',
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                        }}
                    >
                        Average answer:
                    </Typography>
                    <Typography
                        sx={{
                            backgroundColor: '#f0f5fb',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            color: '#053D58',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 600,
                            lineHeight: '15px',
                            letterSpacing: '-0.36px',
                        }}
                    >
                        {averageAnswerText}
                    </Typography>
                </Grid> */}
                {displayLikertScale && (
                    <Grid item sm={5}>
                        <LikertScaleResponseLineChart questionDetails={questionDetails} />
                    </Grid>
                )}
            </Grid>

            {/* Header for Answer Distribution */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                <Typography
                    sx={{
                        color: '#053D58',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        lineHeight: '15px',
                        letterSpacing: '-0.36px',
                    }}
                >
                    Total answer distribution for this company:
                </Typography>
                <Typography
                    sx={{
                        color: '#053D58',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        lineHeight: '15px',
                        letterSpacing: '-0.36px',
                        marginRight: '1.5em'
                    }}
                >
                    Response Percentages:
                </Typography>
            </Box>

            {/* Answer Distribution Rows */}
            <Box
                sx={{
                    // borderRadius: '8px',
                    overflow: 'hidden',
                    // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                    // border: '1px solid #E0E0E0',
                    backgroundColor: '#f0f5fb'
                }}
            >
                {multiple_choice_answer_distribution.map((answer) => {
                    const percentage = total_response_count
                        ? (answer.answer_response_count / total_response_count) * 100
                        : 0;

                    return (
                        <Box
                            key={answer.answer_id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '12px 16px',
                                borderBottom: '1px solid #053D58',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#053D58',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontStyle: 'italic',
                                    fontWeight: 400,
                                    lineHeight: '15px',
                                    maxWidth: '85%'
                                }}
                            >
                                {answer.answer_text}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#053D58',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontStyle: 'italic',
                                    fontWeight: 600,
                                    lineHeight: '15px',
                                    letterSpacing: '-0.42px',
                                    marginRight: '3.5em'
                                }}
                            >
                                {percentage.toFixed(0)}%
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
        </Box >
    );
};

export default MultipleChoiceResponseDisplay;

import React from 'react';
import { Box, Grid, Paper, Typography, Chip } from '@mui/material';
import { TitleTypography, ValueTypography } from '../Overview.styles';
import { AccountDetails } from '../../../../modules/Accounts/types';
import { Link } from 'react-router-dom';
import translation from '../../../../locales/en/translation.json';
import { useBranding } from '../../../../modules/Branding/reducers/getBranding';

const GeneralInfoCard = (prop: { props: AccountDetails }) => {
  const { props } = prop;

  const branding = useBranding();
  const isImpactWashington = branding?.company_name.toLowerCase().includes("washington");

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={3}>
      <Grid container spacing={2} sx={{ minHeight: '240px' }}>
        <Grid item xs={12} md={2} lg={2}>
          <Box sx={{ padding: '22px 28px' }}>
            <Typography
              color="primary"
              variant="subtitle1"
              sx={{
                color: '#000',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}
            >
              General Info
            </Typography>

            <Typography
              color="primary"
              variant="subtitle1"
              sx={{
                color: '#B5B5C3',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}
            >
              Company Information
            </Typography>
          </Box>

          {!isImpactWashington && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {props.company_logo !== "" && <img src={props.company_logo} alt="" style={{ marginTop: 5, maxWidth: '100px', width: 'auto', height: 'auto' }} />}
            </Box>
          )}

        </Grid>
        <Grid item xs={12} md={10} lg={10} paddingRight={'50px'}>
          <Grid
            container
            spacing={2}
            display={'flex'}
            alignContent="space-between"
            flexDirection={'row'}
          >
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box sx={{ padding: '22px 28px' }}>
                <TitleTypography>{translation.business_name}</TitleTypography>
                <ValueTypography>{props.name}</ValueTypography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box sx={{ padding: '22px 28px' }}>
                <TitleTypography>{translation.hq_address}</TitleTypography>
                <ValueTypography>{props.address1}</ValueTypography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box sx={{ padding: '22px 28px', display: 'none' }}>
                {/* <TitleTypography>{translation.tracking_no}</TitleTypography>

                <ValueTypography>{props.id}</ValueTypography> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box sx={{ padding: '22px 28px' }}>
                <TitleTypography>{translation.email}</TitleTypography>
                <Link to={props.email} target="_blank">
                  <ValueTypography sx={{ color: 'rgba(18, 40, 242, 1)' }}>
                    {props.email}
                  </ValueTypography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box sx={{ padding: '22px 28px' }}>
                <TitleTypography>{translation.website}</TitleTypography>
                <Link to={props.website} target="_blank">
                  <ValueTypography sx={{ color: 'rgba(18, 40, 242, 1)' }}>
                    {props.website}
                  </ValueTypography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              {props.is_active ? (
                <Box sx={{ padding: '22px 28px' }}>
                  <Chip
                    sx={{
                      borderRadius: '6px',
                      background: 'rgba(244, 255, 243, 1)',
                      color: '#000',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                    label={'Active'}
                    color="success"
                  />
                </Box>
              ) : (
                <Box sx={{ padding: '22px 28px' }}>
                  <Chip
                    sx={{
                      borderRadius: '6px',
                      background: 'rgba(255, 226, 229, 1)',
                      color: '#000',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                    label={'Inactive'}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GeneralInfoCard;

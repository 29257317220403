import React, { useState, useRef, useEffect } from 'react';
import {
    Form,
    FieldContainer,
    SubTitleTypography,
    TitleTypography,
} from '../../../Assessments/CreateAssessment/CreateAssessment.style';
import {
    Autocomplete,
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
} from '@mui/material';
import translation from '../../../../locales/en/translation.json';
import {
    CancelButton,
} from '../../../Accounts/CreateClient/CreateClient.styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
    Controller,
    FieldValues,
    useFieldArray,
    useForm,
} from 'react-hook-form';
import moment from 'moment';
import CloseIcon from '../../../../assets/svg-components/CloseIcon';
import {
    CustomButton,
    CustomCheckBox,
    ErrorMessage,
    Typography12px400,
    Typography14px400,
    Typography14px500,
    Typography18px500,
} from '../../../../styles/Global.styles';
import { RootStoreType } from '../../../../redux/store.type';
import { AccountDetails } from '../../../Accounts/types';
import useGetClients from '../../../Accounts/hooks/useGetClients';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/store';
import useDebounce from '../../../../hooks/useDebounce';
import { useFormLabelsTheme } from '../../../../hooks/useFormLabelsTheme';
import { CreateEvaluationType, EvaluationCategory } from '../../../Evaluations/type';

import CheckIcon from '@mui/icons-material/Check';
import { CustomAccountBranding } from '../../../Branding/type';
import { UpsertAssessmentPayload, AssessmentStatus } from '../../type';
import useGetAssessmentDefinitions from '../../hooks/useGetAssessmentDefinitions';
import usePutAssessment from '../../hooks/usePutAssessment';
import useGetAccountSettingsUsers from '../../../Accounts/hooks/useGetAccountSettingsUsers';

interface CreateAssessmentProps {
    branding: CustomAccountBranding;
}

const CreateAssessment: React.FC<CreateAssessmentProps> = ({ branding }) => {

    const [inputValue, setInputValue] = React.useState('');
    const formLabelsTheme = useFormLabelsTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { debounce } = useDebounce(1000);

    const accountsResponse = useGetClients();
    const putAssessmentReducer = usePutAssessment();
    const { getAccountSettingsUsers } = useGetAccountSettingsUsers();
    const { data: assessmentDefinitions, loading, error } = useGetAssessmentDefinitions();

    const form = useForm<FieldValues & UpsertAssessmentPayload>({
        defaultValues: {
            name: '',
            is_deleted: false,
            start_date: null,
            end_date: null,
            target_responses: 0,
            status: AssessmentStatus.DRAFT,
            send_email_reminders: false,
            assessment_definition_id: '',
            account_id: '',
            // recipient_list: [''],
            temporary_recipient_list: [{ notify_email: '' }],
        },
    });

    const {
        register,
        formState: { errors },
        control,
        watch,
        setValue,
        handleSubmit,
        clearErrors,
    } = form;

    const [selectedAccount, setSelectedAccount] = React.useState<AccountDetails | null>(null);
    const assessment_definition_id = watch('assessment_definition_id');
    const temporary_recipient_list = watch('temporary_recipient_list'); // This will subscribe to changes of `recipients`
    const [evaluationName, setEvaluationName] = useState<string>('');
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [accountOptions, setAccountOptions] = useState<string[]>([]);

    const companyList = useAppSelector((state) => {
        return state.allClientReducer.clientListReducer.accountListResponse?.results;
    });

    useEffect(() => {
        accountsResponse.clientList();
        allAccountUsers = null;
    }, []);

    useEffect(() => {
        if (companyList) {
            setAccountOptions(companyList.map((company) => company.name));
            accountOptions.unshift('');
        }
    }, [companyList]);

    const { fields, append, remove } = useFieldArray<
        FieldValues & UpsertAssessmentPayload
    >({
        name: 'temporary_recipient_list',
        control,
    });

    useEffect(() => {
        if (selectedAccount && assessment_definition_id) {
            const nameOfAccount =
                companyList &&
                companyList.find(
                    (company) => company.id.toString() == selectedAccount.id,
                )?.name;
            const nameOfEvaluation =
                assessmentDefinitions &&
                assessmentDefinitions.find((definition) => definition.id == assessment_definition_id)
                    ?.name;
            if (nameOfAccount && nameOfEvaluation) {
                setEvaluationName(nameOfAccount + ' ' + nameOfEvaluation);
                clearErrors('name');
            }
        }
    }, [assessment_definition_id, selectedAccount, setValue, clearErrors]);


    useEffect(() => {
        setValue('name', evaluationName);
    }, [evaluationName]);

    useEffect(() => {
        setValue('target_responses', temporary_recipient_list?.length || 0);
    }, [temporary_recipient_list, setValue]);

    const onFormSubmit = (data: UpsertAssessmentPayload) => {
        setSubmitButtonClicked(true);
        if (selectedAccount) {
            const payload: UpsertAssessmentPayload = {
                ...data,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                recipient_list: data.temporary_recipient_list
                    ? data.temporary_recipient_list
                        .filter((email_address) => email_address.notify_email !== '')
                        .map((item) => item.notify_email && item.notify_email)
                    : [],
                start_date: data.start_date
                    ? moment(data.start_date, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
                    : null,
                end_date: data.end_date
                    ? moment(data.end_date, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
                    : null,
                account_id: selectedAccount.id.toString(),
            };
            if (!putAssessmentReducer.loading) {
                putAssessmentReducer.updateAssessment(payload);
            }
        }
    };

    const assessmentIsRequired: string = branding?.evaluation_property_name_translation ?? "Evaluation" + " is required.";
    const assessmentTypeIsRequired: string = branding?.evaluation_property_name_translation ?? "Evaluation" + " type is required.";

    let allAccountUsers = useAppSelector((state: RootStoreType) => {
        return state.allClientReducer?.accountSettingReducer?.accountSetting;
    });

    return (
        <>
            <Box></Box>
            {error ||
                loading ||
                !companyList ||
                !assessmentDefinitions ? (
                <CircularProgress sx={{ margin: '20vh 48%' }} />
            ) :
                (
                    <Form onSubmit={handleSubmit(onFormSubmit)}>
                        <ThemeProvider theme={formLabelsTheme}>
                            <Grid
                                container
                                sx={{
                                    flexWrap: 'wrap-reverse',
                                }}
                            >
                                <Grid item sm={7} xs={10}>
                                    <TitleTypography marginBottom={'34px'}>
                                        {translation.general_info}
                                    </TitleTypography>
                                    <Box sx={{ marginBottom: '70px' }}>
                                        <FieldContainer>
                                            <FormLabel required>{translation.account_name}</FormLabel>
                                            <Autocomplete
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        padding: '0',
                                                    },
                                                }}
                                                value={selectedAccount}
                                                onChange={(_, newAccount) => {
                                                    if (newAccount) {
                                                        setSelectedAccount(newAccount);
                                                        getAccountSettingsUsers(newAccount.id.toString());
                                                    }
                                                }}
                                                inputValue={inputValue}
                                                onInputChange={(_, newInputValue) => {
                                                    if (!newInputValue) {
                                                        setSelectedAccount(null);
                                                    }
                                                    debounce(() => {
                                                        if (newInputValue !== selectedAccount?.name) {
                                                            accountsResponse.clientList({
                                                                search: newInputValue,
                                                            });
                                                        }
                                                    });
                                                    setInputValue(newInputValue);
                                                }}
                                                disablePortal
                                                options={companyList}
                                                getOptionLabel={(option) => option.name}
                                                isOptionEqualToValue={(opt, val) => {
                                                    return opt.id === val.id;
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Search Account By Name"
                                                    />
                                                )}
                                            />

                                            {!selectedAccount && submitButtonClicked && (
                                                <ErrorMessage>
                                                    {translation.account_name_is_required}
                                                </ErrorMessage>
                                            )}
                                        </FieldContainer>
                                    </Box>

                                    <TitleTypography marginBottom={'34px'}>
                                        {translation.configuration}
                                    </TitleTypography>
                                    <Box>
                                        <FieldContainer>
                                            <FormLabel required>
                                                {branding?.evaluation_property_name_translation} Type
                                            </FormLabel>
                                            <Controller
                                                name="assessment_definition_id"
                                                control={control}
                                                rules={{
                                                    required: assessmentTypeIsRequired,
                                                }}
                                                render={({ field }) => (
                                                    <Select
                                                        size="small"
                                                        displayEmpty
                                                        defaultValue={''}
                                                        placeholder={translation.select_an_option}
                                                        {...field}
                                                    >
                                                        {assessmentDefinitions.map((definition) => {
                                                            return (
                                                                <MenuItem key={definition.id} value={definition.id}>
                                                                    {definition.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                )}
                                            />
                                            {errors.assessment_definition_id && (
                                                <ErrorMessage>
                                                    {errors.assessment_definition_id.message?.toString()}
                                                </ErrorMessage>
                                            )}
                                        </FieldContainer>
                                    </Box>

                                    <Box>
                                        <FieldContainer>
                                            <FormLabel required>
                                                Title / Name
                                            </FormLabel>
                                            <Controller
                                                name="name"
                                                control={control}
                                                rules={{
                                                    required: assessmentIsRequired,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        size="small"
                                                        {...field}
                                                        value={evaluationName}
                                                        onChange={(event) => {
                                                            field.onChange(event);
                                                            setEvaluationName(event.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.name && (
                                                <ErrorMessage>
                                                    {errors.name.message?.toString()}
                                                </ErrorMessage>
                                            )}
                                        </FieldContainer>
                                    </Box>

                                    <Box>
                                        <FieldContainer>
                                            <FormLabel>{translation.start_date}</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Controller
                                                    control={control}
                                                    name="start_date"
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    height: '37px',
                                                                },
                                                            }}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            {errors.start_date && (
                                                <ErrorMessage>
                                                    {errors.start_date.message?.toString()}
                                                </ErrorMessage>
                                            )}
                                        </FieldContainer>
                                    </Box>

                                    <Box marginBottom={'70px'}>
                                        <FieldContainer>
                                            <FormLabel>{translation.end_date}</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Controller
                                                    control={control}
                                                    name="end_date"
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    height: '37px',
                                                                },
                                                            }}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            {errors.end_date && (
                                                <ErrorMessage>
                                                    {errors.end_date.message?.toString()}
                                                </ErrorMessage>
                                            )}
                                        </FieldContainer>
                                    </Box>
                                </Grid>
                                <Grid item sm={5} xs={10}>
                                    {allAccountUsers &&
                                        allAccountUsers.filter(
                                            (user) => user.role == 'account_executive',
                                        ).length < 1 ? (
                                        <Typography14px500
                                            sx={{
                                                color: '#B5B5C3',
                                                paddingBottom: '20px',
                                            }}
                                        >
                                            {translation.no_AE_available_text}
                                        </Typography14px500>
                                    ) : (
                                        allAccountUsers &&
                                        allAccountUsers
                                            .filter((user) => user.role == 'account_executive')
                                            .map((user) => {
                                                return (
                                                    <React.Fragment key={user.id}>
                                                        <Box marginBottom={'10px'}>
                                                            <Typography12px400
                                                                sx={{
                                                                    color: '#181C32',
                                                                }}
                                                            >
                                                                {translation.account_executive}
                                                            </Typography12px400>
                                                        </Box>

                                                        <Box
                                                            key={user.id}
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '20px',
                                                                margin: '15px 0',
                                                            }}
                                                        >
                                                            <Avatar
                                                                sx={{
                                                                    height: '50px',
                                                                    width: '50px',
                                                                }}
                                                            >
                                                                {user.fullname[0]}
                                                            </Avatar>
                                                            <Box>
                                                                <Typography18px500
                                                                    sx={{
                                                                        color: '#464E5F',
                                                                        paddingBottom: '10px',
                                                                    }}
                                                                >
                                                                    {user.fullname}
                                                                </Typography18px500>
                                                                <Typography12px400
                                                                    sx={{
                                                                        color: '#717986',
                                                                    }}
                                                                >
                                                                    Business Development Officer
                                                                </Typography12px400>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                                );
                                            })
                                    )}

                                    {allAccountUsers &&
                                        allAccountUsers
                                            .filter((user) => user.role == 'support')
                                            .map((user, index) => {
                                                return (
                                                    user.role == 'support' && (
                                                        <React.Fragment key={user.id}>
                                                            {index == 0 && (
                                                                <Box marginBottom={'10px'}>
                                                                    <Typography12px400
                                                                        sx={{
                                                                            color: '#181C32',
                                                                        }}
                                                                    >
                                                                        {translation.support}
                                                                    </Typography12px400>
                                                                </Box>
                                                            )}

                                                            <Box
                                                                key={user.id}
                                                                sx={{
                                                                    display: 'flex',
                                                                    gap: '20px',
                                                                    margin: '15px 0',
                                                                }}
                                                            >
                                                                <Avatar
                                                                    sx={{
                                                                        height: '50px',
                                                                        width: '50px',
                                                                    }}
                                                                >
                                                                    {user.fullname[0]}
                                                                </Avatar>
                                                                <Box>
                                                                    <Typography18px500
                                                                        sx={{
                                                                            color: '#464e5f',
                                                                            paddingBottom: '10px',
                                                                        }}
                                                                    >
                                                                        {user.fullname}
                                                                    </Typography18px500>
                                                                    <Typography12px400
                                                                        sx={{
                                                                            color: '#717986',
                                                                        }}
                                                                    >
                                                                        Operational Manager
                                                                    </Typography12px400>
                                                                </Box>
                                                            </Box>
                                                        </React.Fragment>
                                                    )
                                                );
                                            })}
                                </Grid>
                            </Grid>

                            <Box marginBottom={'70px'}>
                                <TitleTypography>{translation.direct_recipient}</TitleTypography>
                                <SubTitleTypography marginBottom={'25px'}>
                                    {translation.evaluation_direct_recipient_subtext}
                                </SubTitleTypography>
                                <FormLabel>{translation.user_email}</FormLabel>
                                {fields.map((field, index) => {
                                    return (
                                        <div key={field.id}>
                                            <FieldContainer sx={{ marginBottom: '10px' }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0 5px',
                                                    }}
                                                >
                                                    <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ width: '75%' }}
                                                        {...register(`temporary_recipient_list.${index}.notify_email`, {
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: translation.invalid_email,
                                                            },
                                                        })}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {index != 0 && (
                                                                        <Box
                                                                            component={'span'}
                                                                            onClick={() => remove(index)}
                                                                            sx={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </Box>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Box>
                                                {errors.temporary_recipient_list && errors.temporary_recipient_list[index] && (
                                                    <ErrorMessage>
                                                        {errors.temporary_recipient_list[index]?.message?.toString()}
                                                    </ErrorMessage>
                                                )}
                                            </FieldContainer>
                                            {index === fields.length - 1 && (
                                                <Typography14px400
                                                    marginLeft={'5px'}
                                                    sx={{
                                                        color: '#1C1C1C',
                                                        lineHeight: '20px',
                                                        marginLeft: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => append('')}
                                                >
                                                    + Add Recipient
                                                </Typography14px400>
                                            )}
                                        </div>
                                    );
                                })}
                            </Box>


                            <Box marginBottom={'70px'}>
                                <TitleTypography>{translation.notification}</TitleTypography>
                                <SubTitleTypography marginBottom={'25px'}>
                                    {translation.evaluation_notification_subtext}
                                </SubTitleTypography>
                                <FieldContainer>
                                    <FormLabel>{translation.target_responses}</FormLabel>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        sx={{ width: '58.5%' }}
                                        {...register(`target_responses`, {
                                            pattern: {
                                                value: /^\d*\d+$/,
                                                message: 'Only positive numbers are allowed.',
                                            },
                                        })}
                                    />
                                    {errors.target_responses && (
                                        <ErrorMessage>
                                            {errors.target_responses.message?.toString()}
                                        </ErrorMessage>
                                    )}
                                </FieldContainer>
                            </Box>

                            <Box>
                                <TitleTypography>{translation.reminder}</TitleTypography>
                                <SubTitleTypography marginBottom={'25px'}>
                                    {translation.evaluation_reminder_subtext}
                                </SubTitleTypography>
                                <FieldContainer>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={<CustomCheckBox></CustomCheckBox>}
                                                checkedIcon={
                                                    <CustomCheckBox
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fill: 'black',
                                                                width: '14px',
                                                                height: '14px',
                                                            },
                                                        }}
                                                    >
                                                        <CheckIcon />
                                                    </CustomCheckBox>
                                                }
                                                size="small"
                                                {...register('send_email_reminders')}
                                            />
                                        }
                                        sx={{
                                            fontSize: '10px',
                                        }}
                                        label={
                                            <FormLabel>{translation.send_reminder_email}</FormLabel>
                                        }
                                    />
                                </FieldContainer>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    paddingBottom: '20px',
                                }}
                            >
                                <CustomButton
                                    sx={{
                                        width: '150px',
                                    }}
                                    type="submit"
                                    variant="contained"
                                    size="medium"
                                >
                                    Save
                                </CustomButton>
                                <CancelButton
                                    sx={{
                                        width: '150px',
                                        marginLeft: '12px',
                                    }}
                                    onClick={() => {
                                        navigate('/evaluation-assessment-list');
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                            </Box>
                        </ThemeProvider>
                    </Form>
                )}
        </>
    );
};

export default CreateAssessment;

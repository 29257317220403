import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../redux/store';
import BarChartIcon from '@mui/icons-material/BarChart';
import { SaveButton } from '../../../Accounts/CreateClient/CreateClient.styles';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import useGetAssessmentDetails from '../../hooks/useGetAssessmentDetails';
import useGetScoresByCategory from '../../hooks/useGetScoresByCategory';
import { clearAssessmentDetails } from '../../reducers/getAssessmentDetails';
import { AssessmentQuestionCategory, AssessmentOverviewDetails, QuestionCategoryResult, AssessmentNotesPayload, CategoryScoreDetails, ScoresByCategoryAssessmentDetails } from '../../type'
import { CustomAccountBranding } from '../../../Branding/type';
import CategoryScoreDetailsTab from './CategoryScoreDetailsTab'
import AssessmentAnalysisNotes from '../../AssessmentDetails/components/Analysis/AssessmentAnalysisNotes';
import useAssessmentNotes from '../../hooks/usePutAssessmentNotes';

interface CategoryAnalysisContainerProps {
    branding: CustomAccountBranding;
    id: string;
}

const CategoryAnalysisContainer: React.FC<CategoryAnalysisContainerProps> = ({ branding, id }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getAssessmentDetailsReducer = useGetAssessmentDetails();
    const getScoresByCategoryReducer = useGetScoresByCategory();

    const [value, setValue] = useState<string>('');

    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setCurrentIndex(0);
    };

    const assessmentDetail = useAppSelector((state) => {
        return state.assessmentReducers.getAssessmentDetailsReducer?.assessment_details;
    });

    const assessmentCategoryScoreDetails = useAppSelector((state) => {
        return state.assessmentReducers.getScoresByCategoryReducer?.assessment_category_details as ScoresByCategoryAssessmentDetails;
    });

    React.useEffect(() => {
        dispatch(clearAssessmentDetails());
        getAssessmentDetailsReducer.getDetails(id);
        getScoresByCategoryReducer.getAssessmentCategoryScoreDetails(id);
    }, [id]);

    React.useEffect(() => {
        getAssessmentDetailsReducer.getDetails(id);
        getScoresByCategoryReducer.getAssessmentCategoryScoreDetails(id);
    }, []);

    const { updateAssessmentNotes } = useAssessmentNotes();

    const onNotesSubmit = (data: AssessmentNotesPayload) => {
        const defaultValues = {
            assessment_id: assessmentCategoryScoreDetails?.uuid
        };
        updateAssessmentNotes({
            ...defaultValues,
            ...data,
        });
        getAssessmentDetailsReducer.getDetails(id);
        getScoresByCategoryReducer.getAssessmentCategoryScoreDetails(id);
    };

    useEffect(() => {
        if (assessmentCategoryScoreDetails && assessmentCategoryScoreDetails.scores_by_category.length > 0) {
            setValue(assessmentCategoryScoreDetails.scores_by_category[0].category_id.toString());
        }
    }, [assessmentCategoryScoreDetails]);

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '20px',
                }}
            >
                <SaveButton
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Back
                </SaveButton>
            </Box>
            {!assessmentDetail ||
                !assessmentCategoryScoreDetails ? (
                <CircularProgress sx={{ margin: '20vh 48%' }} />
            ) : (
                assessmentDetail &&
                assessmentCategoryScoreDetails && (
                    <TabContext value={value}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>
                            <TabList
                                sx={{
                                    flexWrap: 'wrap', // Allow the tabs to wrap into multiple rows
                                    '& .MuiTabs-flexContainer': {
                                        flexWrap: 'wrap', // Ensure the tab container wraps as well
                                    },
                                    '& .MuiTab-root': {
                                        textTransform: 'none',    // Disable the uppercase transformation
                                        // minWidth: '150px',        // Set a minimum width for each "button"
                                        fontSize: '0.75em',
                                        padding: '0em 0.5em',      // Padding to make them look like buttons
                                        margin: '0px 5px 0px 5px',            // Add spacing between the tabs
                                        borderRadius: '5px',   // Rounded corners for button look
                                        borderBottomLeftRadius: '0px',
                                        borderBottomRightRadius: '0px',
                                        border: '1px solid #f4f4f4', // Border to give a button-like appearance
                                        borderBottom: 'none',
                                        backgroundColor: '#ffffff', // Light background to mimic button
                                        color: '#333',            // Text color
                                        '&:hover': {
                                            backgroundColor: '#fbfbfb', // Slightly darker background on hover
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: branding.primary_color, // Button-like background for selected tab
                                            color: 'white',             // White text on selected tab
                                            border: `1px solid ${branding.primary_color}`,     // Border to match the selected background
                                        },
                                    },
                                }}
                                onChange={handleChange}
                                TabIndicatorProps={{
                                    style: { display: 'none' }, // Hide the default indicator
                                }}
                                aria-label="styled tabs as buttons"
                            >
                                {assessmentCategoryScoreDetails.scores_by_category.map(
                                    (category: CategoryScoreDetails) => (
                                        <Tab
                                            key={category.category_id}
                                            label={category.category_name}
                                            value={category.category_id.toString()}
                                        />
                                    ))}
                                <Tab label="Notes" value="notes" />
                            </TabList>
                        </Box>

                        {assessmentCategoryScoreDetails.scores_by_category.map(
                            (category: CategoryScoreDetails) => (
                                <TabPanel
                                    sx={{ padding: '0' }}
                                    value={category.category_id.toString()}
                                    key={category.category_id}
                                >
                                    <CategoryScoreDetailsTab
                                        branding={branding}
                                        categoryScoreDetail={category}
                                    />
                                </TabPanel>
                            ),
                        )}
                        <TabPanel value="notes">
                            <AssessmentAnalysisNotes
                                initialAssessmentNotes={assessmentDetail.assessment_notes ?? []}
                                onNotesSubmit={onNotesSubmit}
                            />
                        </TabPanel>
                    </TabContext>
                )
            )}

            {/* <IndividualResponseModal
                open={modalOpen}
                handleClose={handleCloseModal}
                evaluationId={id || ''}
                questionCategoryId={value || ''}
                questionCategory={selectedCategory || ''}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
            /> */}
        </div>
    );
};

export default CategoryAnalysisContainer;

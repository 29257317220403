import React, { useState, FunctionComponent, ChangeEvent } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import IntakeFormCompanySize from './IntakeFormCompanySize';
import IntakeFormCountyIntake from './IntakeFormCountyIntake';
import IntakeFormContactForm from './IntakeFormContactForm';
import IntakeFormIndustryType from './IntakeFormIndustryType';
import IntakeFormRegionalManufacturersAssoc from './IntakeFormRegionalManufacturersAssoc';
import IntakeFormLegalStatement from './IntakeFormLegalStatement';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntakeFormCompanySelectionForm from './IntakeFormCompanySelectionForm';
import { CustomAccountBranding } from '../../modules/Branding/type';
import { AssessmentDefinition } from '../../modules/Assessments/type';

const ContinueButton: FunctionComponent<{
  onClick: () => void;
  disabled: boolean;
}> = ({ onClick, disabled }) => (
  <div>
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      Continue <ArrowForwardIcon sx={{ marginLeft: '8%' }} />
    </Button>
  </div>
);

const PreviousButton: FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => (
  <div>
    <Button variant="contained" color="primary" onClick={onClick}>
      <ArrowBackIcon sx={{ marginRight: '8%' }} /> Back
    </Button>
  </div>
);

interface IntakeFormDemographicsProps {
  formData: {
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    address: string;
    companyName: string;
    companySize: string;
    industryType: string[];
    regionalManufacturersAssoc: string[];
    address2: string;
    phoneNumber: string;
    city: string;
    state: string;
    zipCode: string;
    county: string;
    participationAgreement: string;
  };
  onFormChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
      | SelectChangeEvent<string[]>,
  ) => void;
  onNextClick: () => void;
  onStepChange: (number) => void;
  branding: CustomAccountBranding;
  assessmentDefinitions: AssessmentDefinition[] | null;
}

const IntakeFormDemographics: React.FC<IntakeFormDemographicsProps> = ({
  formData,
  onFormChange,
  onNextClick,
  onStepChange,
  branding,
  assessmentDefinitions
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep == 0) {
      //onStepChange is prop method to set parent tab back to previous tab
      //only want to do this when on the first or 0 step
      onStepChange(1);
      return;
    }
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const isStepValid = (): boolean => {
    switch (currentStep) {
      case 0:
        //theres got to be a better way, doing this for times sake.
        return (
          formData.firstName !== '' &&
          formData.lastName !== '' &&
          formData.jobTitle !== '' &&
          formData.email !== '' &&
          formData.address !== '' &&
          formData.companyName !== '' &&
          formData.address2 !== '' &&
          formData.phoneNumber !== '' &&
          formData.city !== '' &&
          formData.state !== '' &&
          formData.zipCode !== ''
        );
      case 1:
        return formData.county !== '';
      case 2:
        return formData.companySize !== '';
      case 3:
        return formData.industryType.length > 0;
      case 4:
        return formData.regionalManufacturersAssoc.length > 0;
      case 5:
        return formData.participationAgreement == 'I Agree';
      default:
        return true;
    }
  };

  const isNextButtonDisabled = !isStepValid();
  const [isNewCompanyNeeded, setIsNewCompanyNeeded] = useState(false);
  const handleCompanySelect = (companyData = true) => {
    setIsNewCompanyNeeded(Boolean(companyData));
  };
  return (
    <Container>
      {!isNewCompanyNeeded ? (
        <IntakeFormCompanySelectionForm
          onCompanySelect={handleCompanySelect}
          branding={branding}
          assessmentDefinitions={assessmentDefinitions}
        />
      ) : (
        <>
          {currentStep === 0 && (
            <>
              <IntakeFormContactForm
                formData={formData}
                onFormChange={onFormChange}
              />
              <Grid
                item
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: '5%',
                }}
              >
                <PreviousButton onClick={handlePreviousStep} />
                <ContinueButton
                  onClick={handleNextStep}
                  disabled={isNextButtonDisabled}
                />
              </Grid>
            </>
          )}
          {currentStep === 1 && (
            <>
              <IntakeFormCountyIntake
                formData={formData}
                onFormChange={onFormChange}
              />
              <Grid
                item
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: '45%',
                }}
              >
                <PreviousButton onClick={handlePreviousStep} />
                <ContinueButton
                  onClick={handleNextStep}
                  disabled={isNextButtonDisabled}
                />
              </Grid>
            </>
          )}
          {currentStep === 2 && (
            <>
              <IntakeFormCompanySize
                formData={formData}
                onFormChange={onFormChange}
              />
              <Grid
                item
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: '45%',
                }}
              >
                <PreviousButton onClick={handlePreviousStep} />
                <ContinueButton
                  onClick={handleNextStep}
                  disabled={isNextButtonDisabled}
                />
              </Grid>
            </>
          )}
          {currentStep === 3 && (
            <>
              <IntakeFormIndustryType
                formData={formData}
                onFormChange={onFormChange}
              />
              <Grid
                item
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: '45%',
                }}
              >
                <PreviousButton onClick={handlePreviousStep} />
                <ContinueButton
                  onClick={handleNextStep}
                  disabled={isNextButtonDisabled}
                />
              </Grid>
            </>
          )}
          {currentStep === 4 && (
            <>
              <IntakeFormRegionalManufacturersAssoc
                formData={formData}
                onFormChange={onFormChange}
              />
              <Grid
                item
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: '45%',
                }}
              >
                <PreviousButton onClick={handlePreviousStep} />
                <ContinueButton
                  onClick={handleNextStep}
                  disabled={isNextButtonDisabled}
                />
              </Grid>
            </>
          )}
          {currentStep === 5 && (
            <>
              <IntakeFormLegalStatement
                formData={formData}
                onFormChange={onFormChange}
              />
              <Grid
                item
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: '5%',
                }}
              >
                <PreviousButton onClick={handlePreviousStep} />
                <ContinueButton
                  onClick={onNextClick}
                  disabled={isNextButtonDisabled}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default IntakeFormDemographics;

import React from 'react';
import { Box, Grid, Paper, Typography, Chip } from '@mui/material';
import { TitleTypography, ValueTypography } from '../Overview.styles';
import { AccountContact } from '../../../../modules/Accounts/types';
import { Link } from 'react-router-dom';
import translation from '../../../../locales/en/translation.json';
import { useBranding } from '../../../../modules/Branding/reducers/getBranding';

const ContactCard = (prop: { account_contact: AccountContact[] }) => {
  const data = prop.account_contact;

  const branding = useBranding();
  const isImpactWashington = branding?.company_name.toLowerCase().includes("washington");

  if (!isImpactWashington) {
    return (
      <Paper
        sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}
        elevation={3}
      >
        <Grid container spacing={2} minHeight={'240px'} marginTop={1}>
          <Grid item xs={12} md={2} lg={2}>
            <Box sx={{ padding: '0px 28px' }}>
              <Typography
                color="primary"
                variant="subtitle1"
                sx={{
                  color: '#000',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                }}
              >
                Contacts
              </Typography>

              <Typography
                color="primary"
                variant="subtitle1"
                sx={{
                  color: '#B5B5C3',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                Designate a primary point of Contact (POC) and multiple alternates
                for added awareness and management
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10} lg={10} paddingRight={'50px'}>
            {data.length > 0 ? (
              data.map((item, index) => (
                <Paper
                  sx={{ width: '100%', overflow: 'hidden', marginBottom: '10px' }}
                  key={index}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                      <Box sx={{ padding: '22px 28px' }}>
                        {item.contact_photo && (
                          <img
                            src={item.contact_photo}
                            alt=""
                            style={{
                              maxWidth: '100px',
                              width: 'auto',
                              height: 'auto',
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.name}</TitleTypography>
                            <ValueTypography>
                              {(item?.first_name && item?.last_name) ? `${item.first_name as string} ${item.last_name as string}` : item?.name ?? ''}
                            </ValueTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.role}</TitleTypography>
                            <ValueTypography>{item.role}</ValueTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.phone_no}</TitleTypography>
                            <ValueTypography>{item.phone}</ValueTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.email}</TitleTypography>
                            <Link to={item.email} target="_blank">
                              <ValueTypography sx={{ color: 'rgba(18, 40, 242, 1)' }}>
                                {item.email}
                              </ValueTypography>
                            </Link>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.location}</TitleTypography>
                            <ValueTypography>{item.location}</ValueTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <Chip
                              sx={{
                                borderRadius: '6px',
                                background: item.is_primary_contact
                                  ? 'rgba(18, 40, 242, 0.20)'
                                  : 'rgba(0, 219, 153, 0.2)',
                                color: '#000',
                                fontWeight: '600',
                                textAlign: 'center',
                              }}
                              label={item.is_primary_contact ? 'Primary' : 'Alternate'}
                              color="success"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              ))
            ) : (
              <Typography
                sx={{
                  color: '#A3A3A3',
                  fontFamily: 'Inter',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}
              >
                No records found.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  } else {
    return (
      <Paper
        sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}
        elevation={3}
      >
        <Grid container spacing={2} minHeight={'240px'} marginTop={'35px'}>
          <Grid item xs={12} md={2} lg={2}>
            <Box sx={{ padding: '22px 28px' }}>
              <Typography
                color="primary"
                variant="subtitle1"
                sx={{
                  color: '#000',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                }}
              >
                Contacts
              </Typography>

              <Typography
                color="primary"
                variant="subtitle1"
                sx={{
                  color: '#B5B5C3',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                Designate a primary point of Contact (POC) and multiple alternates
                for added awareness and management
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10} lg={10} paddingRight={'50px'}>
            {data.length > 0 ? (
              data.map((item, index) => (
                <Paper
                  sx={{ width: '100%', overflow: 'hidden', marginBottom: '10px' }}
                  key={index}
                >
                  <Grid container spacing={2} sx={{}}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.name}</TitleTypography>
                        <ValueTypography>
                          {(item?.first_name && item?.last_name) ? `${item.first_name as string} ${item.last_name as string}` : item?.name ?? ''}
                        </ValueTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.role}</TitleTypography>
                        <ValueTypography>{item.role}</ValueTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.phone_no}</TitleTypography>
                        <ValueTypography>{item.phone}</ValueTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.email}</TitleTypography>
                        <Link to={item.email} target="_blank">
                          <ValueTypography sx={{ color: 'rgba(18, 40, 242, 1)' }}>
                            {item.email}
                          </ValueTypography>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.location}</TitleTypography>
                        <ValueTypography>{item.location}</ValueTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      {item.is_primary_contact ? (
                        <Box sx={{ padding: '22px 28px' }}>
                          <Chip
                            sx={{
                              borderRadius: '6px',
                              background: 'rgba(18, 40, 242, 0.20)',
                              color: '#000',
                              fontWeight: '600',
                              textAlign: 'center',
                            }}
                            label={'Primary'}
                            color="success"
                          />
                        </Box>
                      ) : (
                        <Box sx={{ padding: '22px 28px' }}>
                          <Chip
                            sx={{
                              borderRadius: '6px',
                              background: 'rgba(0, 219, 153, 0.2)',
                              color: '#000',
                              fontWeight: '600',
                              textAlign: 'center',
                            }}
                            label={'Alternate'}
                            color="success"
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              ))
            ) : (
              <Typography
                sx={{
                  color: '#A3A3A3',
                  fontFamily: 'Inter',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}
              >
                No records found.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }
};

export default ContactCard;

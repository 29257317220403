import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Label } from '@mui/icons-material';
import counties from './Counties';
import MenuHelper from './Helpers';
import states from './States';

interface IntakeFormContactProps {
  formData: {
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    address: string;
    companyName: string;
    address2: string;
    phoneNumber: string;
    city: string;
    state: string;
    zipCode: string;
    county: string;
  };
  onFormChange: (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
  ) => void;
}

const IntakeFormContactForm: React.FC<IntakeFormContactProps> = ({
  formData,
  onFormChange,
}) => {
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);

  // const isFormComplete = Object.values(formData).every(
  //   (value) => value.trim() !== '',
  // );

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    onFormChange(event);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValidEmail(emailRegex.test(email));
  };

  const formatPhoneNumber = (value: string) => {
    // Remove all non-digit characters
    const cleanedNumber = value.replace(/\D/g, '');

    // break into groups for formatting the string as (123) 456-7890
    const match = cleanedNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      let formattedNumber = '';
      if (match[1]) formattedNumber += `(${match[1]}`;
      if (match[1].length === 3) formattedNumber += `) `;
      if (match[2]) formattedNumber += match[2];
      if (match[2].length === 3) formattedNumber += `-`;
      if (match[3]) formattedNumber += match[3];
      return formattedNumber;
    }

    return value;
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = formatPhoneNumber(event.target.value);
    const phone = event.target.value;
    if (phone.length <= 14) {
      onFormChange(event);
      const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      setIsValidPhone(phoneRegex.test(phone));
    }
  };

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4" color="primary">
          Contact and Company Demographic Information
        </Typography>

        <Typography variant="body1" mt="3%">
          Under this section, you&apos;ll provide basic information about your
          company, including name, industry type, size, location, years in
          operation, and the relevant contact details of the person or
          department responsible for the survey participation. This helps to
          categorize responses appropriately and provides a point of contact for
          any follow-up required.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="body1" gutterBottom>
          Contact Information
        </Typography>
      </Box>

      <Grid container spacing={2} mt="3%">
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="First Name*"
              fullWidth
              name="firstName"
              value={formData.firstName}
              onChange={(event) =>
                onFormChange(event as ChangeEvent<HTMLInputElement>)
              }
              sx={{ width: '50%' }}
            />
            {formData.firstName && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Last Name*"
              fullWidth
              name="lastName"
              value={formData.lastName}
              onChange={(event) =>
                onFormChange(event as ChangeEvent<HTMLInputElement>)
              }
              sx={{ width: '50%' }}
            />
            {formData.lastName && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Job Title*"
              fullWidth
              name="jobTitle"
              value={formData.jobTitle}
              onChange={(event) =>
                onFormChange(event as ChangeEvent<HTMLInputElement>)
              }
              sx={{ width: '50%' }}
            />
            {formData.jobTitle && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Email*"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleEmailChange}
              sx={{ width: '50%' }}
            />
            {isValidEmail && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Address*"
              fullWidth
              name="address"
              value={formData.address}
              onChange={(event) =>
                onFormChange(event as ChangeEvent<HTMLInputElement>)
              }
              sx={{ width: '50%' }}
            />
            {formData.address && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Box my="3%">
        <Typography variant="body1">Company Demographic Information</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Company Name*"
              fullWidth
              name="companyName"
              value={formData.companyName}
              onChange={(event) =>
                onFormChange(event as ChangeEvent<HTMLInputElement>)
              }
              sx={{ width: '50%' }}
            />
            {formData.companyName && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Company Address*"
              fullWidth
              name="address2"
              value={formData.address2}
              onChange={(event) =>
                onFormChange(event as ChangeEvent<HTMLInputElement>)
              }
              sx={{ width: '50%' }}
            />
            {formData.address2 && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Phone Number*"
              fullWidth
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              sx={{ width: '50%' }}
              type="text"
            />
            {isValidPhone && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="City*"
              fullWidth
              name="city"
              value={formData.city}
              onChange={(event) =>
                onFormChange(event as ChangeEvent<HTMLInputElement>)
              }
              sx={{ width: '50%' }}
            />
            {formData.city && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box alignItems="center">
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ display: 'inline' }}
            >
              <Select
                id="state-select"
                name="state"
                renderValue={(selected) =>
                  selected ? selected : 'Select State*'
                }
                value={formData.state}
                onChange={(event) =>
                  onFormChange(event as SelectChangeEvent<string>)
                }
                MenuProps={MenuHelper}
                displayEmpty
                sx={{
                  width: '50%',
                  color: formData.state ? 'inherit' : 'rgba(0, 0, 0, 0.6)',
                }}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
              {formData.state && (
                <CheckCircleOutlineIcon
                  sx={{ color: 'green', marginLeft: '8px', verticalAlign: 'middle' }}
                />
              )}
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Zip Code*"
              fullWidth
              name="zipCode"
              value={formData.zipCode}
              onChange={(event) =>
                onFormChange(event as ChangeEvent<HTMLInputElement>)
              }
              sx={{ width: '50%' }}
            />
            {formData.zipCode && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntakeFormContactForm;
